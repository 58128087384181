const API_URL = 'https://serv-app.qaizenx.com/api/';
const IAM_URL = 'https://serv-iam.qaizenx.com/api/';

const configURL = {
    verify_vendor: `${IAM_URL}verify-vendor`,
    loginURL: `${API_URL}login/partner`,
    verify_vendor_url: `${IAM_URL}check-webaddress`,
    login_iam_url: `${API_URL}login-with-credentials`,
    get_user_detail: `${API_URL}v1/iam/user-details`,
    get_company_roles: `${API_URL}v1/iam/get-roles`,
    get_company_profiles: `${API_URL}v1/iam/get-profiles`,
    delete_person: `${API_URL}v1/iam/user-delete`,
    profile_delete: `${API_URL}v1/iam/profile-delete`,
    profile_detail: `${API_URL}v1/iam/profile-details`,
    save_profile: `${API_URL}v1/iam/profile`,
    iam_save_partner: `${IAM_URL}partner`,
    iam_partners_list: `${IAM_URL}partner`,
    iam_compnay_add: `${IAM_URL}partner/company`,
    get_company_token: `${IAM_URL}partner/generate-company-token`,
    delete_category: `${API_URL}v1/partner-admin/category-delete`,
    edit_category: `${API_URL}v1/partner-admin/category-update`,
    compnay_delete: `${API_URL}v1/partner-template/delete-company`,
    get_compnay_details: `${API_URL}v1/partner-template/get-company-details`,
    save_compnay_details: `${API_URL}v1/partner-template/company-create-update`,
    company_listing: `${API_URL}v1/partner-template/company-listing`,
    partner_categoryDs: `${API_URL}v1/partner-admin/category-list`,
    partner_themeListing: `${API_URL}v1/partner-admin/theme-list`,
    partner_subthemeListing: `${API_URL}v1/partner-admin/sub-theme-list`,
    partner_addTheme: `${API_URL}v1/partner-admin/theme-save`,
    partner_addSubTheme: `${API_URL}v1/partner-admin/sub-theme-save`,
    partner_template_dashboard_initials: `${API_URL}v1/partner-template/initials-data`,
    partner_categoryListing: `${API_URL}v1/partner-admin/categoryview`,
    partner_categorySave: `${API_URL}v1/partner-admin/savecategory`,
    partner_save_template_url: `${API_URL}v1/partner-template/saving`,
    partner_surveythemeDs: `${API_URL}v1/partner-admin/theme/thememapping`,
    partner_surveySubThemeDs: `${API_URL}v1/partner-admin/theme/subthememapping`,
    partner_get_template_builder_initals: `${API_URL}v1/partner-template/template-questions-initials`,
    partner_save_template_builder_setting: `${API_URL}v1/partner-template/save-template-builder`,
    partner_save_template_question: `${API_URL}v1/partner-template/save-template-question`,
    partner_template_category: `${API_URL}v1/partner-admin/categoryviewer`,
    partner_get_template_details_url: `${API_URL}v1/partner-template/view-templates`,
    getQuestionMapData: `${API_URL}v1/partner-template/get-mappings-data`,
    saveQuestionMapData: `${API_URL}v1/partner-template/save-mappings-data`,
    partner_category_templates_url: `${API_URL}v1/partner-template/category-wise-templates`,
    partner_company_templates: `${API_URL}v1/partner-template/company-wise-templates`,
    partner_category_templates_metadata_url: `${API_URL}v1/partner-template/category-wise-counts`,
    recent_activities: `${API_URL}v1/recent-activities`,
    clear_activities: `${API_URL}v1/clear-activities`,
    partner_allocate_template: `${API_URL}v1/partner-admin/allocateTemplate`,
    partner_themeQuestionListing: `${API_URL}v1/partner-admin/theme/questionlisting`,
    partner_subThemesData: `${API_URL}v1/partner-admin/theme/subthemedataonload`,
    partner_surveyQuestionMapping: `${API_URL}v1/partner-admin/theme/surveyquestionmapping`,
    partner_dashboard_companies: `${IAM_URL}partner/dashbaord-counts`,
    partner_dashboard_template: `${API_URL}v1/partner-admin/dashboard-counts`,
    partner_delete_template: `${API_URL}v1/partner-template/deleting`,
    partner_add_company_data: `${API_URL}v1/company/add-company-data`,
    update_company_user: `${API_URL}v1/iam/update-user-profile`,
    partner_updateThemeRow: `${API_URL}v1/partner-admin/theme/themeEditor/theme`,
    partner_updateSubthemeRow: `${API_URL}v1/partner-admin/theme/themeEditor/subtheme`,
    partner_deleteRow: `${API_URL}v1/partner-admin/theme/delete-master`,
    getReportTypes: `${API_URL}v1/survey/get-report-types`,
    get_communication_templates: `${API_URL}v1/partner/get-communication-templates`,
    save_communication_template: `${API_URL}v1/partner/save-communication-template`,
    update_communication_template: `${API_URL}v1/partner/update-communication-template`,
    delete_communication_template: `${API_URL}v1/partner/delete-communication-template`,
    view_template_detail: `${API_URL}v1/partner/view-communication-template`,
    user_list: `${IAM_URL}partner/user-list`,
    delete_user: `${IAM_URL}partner/user-delete`,
    create_user: `${IAM_URL}partner/user`,
    industry_type: `${API_URL}v1/survey/industry-type`,
    question_bank_list: `${API_URL}v1/partner-template/question-bank-list`,
    addToQuestionBank: `${API_URL}v1/partner-template/create-questions-list`,
    check_emailId: `${IAM_URL}check-emailId`,
    communication_job_details: `${API_URL}v1/partner/post-communication-job-details`,
    company_wise_survey_list: `${API_URL}v1/survey/list`,
    update_advanced_report_access: `${API_URL}v1/survey/update-advanced-report-access`,
    create_benchamark : `${API_URL}v1/create-benchmark`,
    get_benchmark_list:`${API_URL}v1/get-benchmarks`,
    delete_custom_benchmark :`${API_URL}v1/delete-benchmark`,
    adding_multiple_statements_into_benchmark:`${API_URL}v1/benchmark/add-statements`,
    get_statement_by_Benchmark_Id:`${API_URL}v1/benchmark/statements`,
    update_benhmark:`${API_URL}v1/update-benchmark`,
    benchmark_bulk_upload_template:`${API_URL}v1/benchmark/statements/download-excel`,
    benchmark_bulk_upload: `${API_URL}v1/benchmark/statements/upload-excel`,
    delete_question_bank_questions:`${API_URL}v1/partner-template/delete-question-bank-questions`,
    delete_logo:`${API_URL}delete-logo`,
}
export default configURL;
